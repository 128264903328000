import React from 'react';

import { PodcastsMainComponent } from '@pages-impl/podcasts/podcasts-main-component/PodcastsMainComponent';
import featuredImage from '@images/header-images/featured_image_PP_SDN.jpg';

export default function Sdn(props) {
  const SEO_title = 'SDN | revolution or evolution of traditional networks? Podcast';
  const SEO_description =
    "We present a podcast in which we discuss SDN. Is it a revolution or an evolution of traditional networks? Learn more from CodiLime's podcast.";

  return (
    <PodcastsMainComponent
      activePodcast={1}
      {...props}
      SEO_description={SEO_description}
      SEO_title={SEO_title}
      featuredImage={featuredImage}
    />
  );
}
